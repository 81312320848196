<template>
  <div class="carousel p-1">
    <div id="carouselServices" class="carousel slide" data-bs-ride="carousel" data-bs-interval="3000">
      <div class="carousel-indicators">
        <button
          v-for="(item, index) in getNumberOfSlides"
          :key="index"
          type="button"
          :data-bs-target="'#carouselServices'"
          :data-bs-slide-to="index"
          :class="{ 'active': index === 0 }"
          :aria-label="`Slide ${index + 1}`"
        ></button>
      </div>
      <div class="carousel-inner">
        <div
          v-for="(group, groupIndex) in getResponsiveGroups"
          :key="groupIndex"
          class="carousel-item"
          :class="{ 'active': groupIndex === 0 }"
        >
          <div class="row">
            <div 
              v-for="(item, itemIndex) in group" 
              :key="itemIndex" 
              :class="getColumnClass"
            >
              <img class="d-block w-100" :src="item.src" :alt="item.alt">
            </div>
          </div>
        </div>
      </div>
      <a class="carousel-control-prev custom-control" href="#carouselServices" role="button" data-bs-slide="prev">
        <i class="bi bi-arrow-left"></i>
        <span class="visually-hidden">Previous</span>
      </a>
      <a class="carousel-control-next custom-control" href="#carouselServices" role="button" data-bs-slide="next">
        <i class="bi bi-arrow-right"></i>
        <span class="visually-hidden">Next</span>
      </a>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */

export default {
  name: 'CarruselComponent',
  data() {
    return {
      items: [
        { src: require('@/assets/nuevos servicios/1.svg'), alt: 'First slide' },
        { src: require('@/assets/nuevos servicios/2.svg'), alt: 'Second slide' },
        { src: require('@/assets/nuevos servicios/3.svg'), alt: 'Third slide' },
        { src: require('@/assets/nuevos servicios/4.svg'), alt: 'Fourth slide' },
        { src: require('@/assets/nuevos servicios/5.svg'), alt: 'Fifth slide' },
        { src: require('@/assets/nuevos servicios/6.svg'), alt: 'Sixth slide' }
      ],
      windowWidth: window.innerWidth
    };
  },
  computed: {
    itemsPerGroup() {
      if (this.windowWidth <= 576) return 1;
      if (this.windowWidth <= 768) return 2;
      return 3;
    },
    getColumnClass() {
      if (this.windowWidth <= 576) return 'col-12';
      if (this.windowWidth <= 768) return 'col-6';
      return 'col-4';
    },
    getResponsiveGroups() {
      return this.getItemGroups(this.items, this.itemsPerGroup);
    },
    getNumberOfSlides() {
      return Math.ceil(this.items.length / this.itemsPerGroup);
    }
  },
  methods: {
    getItemGroups(items, groupSize) {
      if (!items) return [];
      const groups = [];
      for (let i = 0; i < items.length; i += groupSize) {
        groups.push(items.slice(i, i + groupSize));
      }
      return groups;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$nextTick(() => {
    // Inicializa el carrusel de Bootstrap solo si está disponible
    const carouselElement = document.querySelector('#carouselServices');
    if (carouselElement) {
      new bootstrap.Carousel(carouselElement);  // Inicializa el carrusel de Bootstrap
    }
  });
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style scoped>
.carousel {
  background: linear-gradient(#EDEDED);
  padding: 2rem;
  position: relative;
  overflow: hidden;
}

.carousel-indicators {
  bottom: -15px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
}

.carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #ccc;
  border: 0;
  opacity: 1;
  transition: background-color 0.3s ease;
}

.carousel-indicators .active {
  background-color: #FFC844;;
}

.carousel-item .row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.carousel-item [class^="col-"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.carousel-item img {
  max-height: 120px;
  object-fit: contain;
  width: auto !important;
}

.custom-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  color: #0044cc;
  font-size: 2rem;
  text-decoration: none;
  z-index: 1;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.custom-control:hover {
  color: #0044cc;
}

.carousel-control-prev.custom-control {
  left: 0.5rem;
}

.carousel-control-next.custom-control {
  right: 0.5rem;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

@media (max-width: 1200px) {
  .carousel-item [class^="col-"] {
    padding: 0 10px;
  }
  
  .custom-control {
    font-size: 1.75rem;
    width: 35px;
    height: 35px;
  }
}

@media (max-width: 992px) {
  .carousel-item [class^="col-"] {
    padding: 0 8px;
  }

  .carousel-item img {
    max-height: 150px;
  }
  
  .custom-control {
    font-size: 1.5rem;
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 768px) {
  .carousel {
    padding: 4rem;
  }

  .carousel-item [class^="col-"] {
    padding: 0 5px;
  }

  .carousel-item img {
    max-height: 100px;
  }

  .carousel-indicators {
    bottom: -10px;
  }
  
  .custom-control {
    font-size: 1.25rem;
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 576px) {
  .carousel-item img {
    max-height: 200px;
  }
  
  .custom-control {
    font-size: 1rem;
    width: 24px;
    height: 24px;
  }
  
  .carousel-control-prev.custom-control {
    left: 0.25rem;
  }

  .carousel-control-next.custom-control {
    right: 0.25rem;
  }
}
</style>