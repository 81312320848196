import { createRouter, createWebHistory } from 'vue-router';
/* eslint-disable */
// Importar las vistas
import HomePage from '@/views/HomePage.vue';
import GuardiasPage from '@/views/GuardiasPage.vue';
import IntendenciaPage from '@/views/IntendenciaPage.vue';
import NosotrosPage from '@/views/NosotrosPage.vue';
import ContactoPage from '@/views/ContactoPage.vue';
import CotizaPage from '@/views/CotizaPage.vue';

// Definir rutas
const routes = [
  { path: '/', component: HomePage, meta: { title: 'Inicio' } },
  { path: '/guardias', component: GuardiasPage, meta: { title: 'Guardias' } },
  { path: '/intendencia', component: IntendenciaPage, meta: { title: 'Intendencia' } },
  { path: '/nosotros', component: NosotrosPage, meta: { title: 'Nosotros' } },
  { path: '/contacto', component: ContactoPage, meta: { title: 'Contacto' } },
  { path: '/cotiza', component: CotizaPage, meta: { title: 'Cotiza Ahora' } },
];

// Crear el router
const router = createRouter({
  history: createWebHistory('/'), // Base del proyecto (publicPath en vue.config.js)
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Desplazarse hacia la parte superior de la página en cada cambio de ruta
    return { top: 0 };
  }
});

// Configurar título dinámico
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'SIP';
  next();
});

export default router;
