<template>
  <BotonWsp></BotonWsp>
  <HeroComponent pageName="home"></HeroComponent>

  <!-- Fila con dos columnas -->
  <div class="row align-items-center justify-content-center">
    <!-- Columna para la imagen -->
    <div class="col-12 col-lg-4 py-4">
      <img src="../assets/imagenes/home2.svg" alt="Descripción de la imagen"
        class="img-fluid mx-auto d-block tablet-image">
    </div>
    <!-- Columna para el texto -->
    <div class="col-12 col-lg-6 col-md-7">
      <div class="texto-2">
        <p>
          Trabajamos para brindarte servicios especializados en seguridad privada e intendencia,
          principalmente. Conocemos la importancia de contar con la confianza, seguridad y
          limpieza necesaria en tu hogar o negocio, por lo que contamos con personal altamente
          capacitado para atender tus necesidades, brindándote el cuidado y la protección que
          requieres.
        </p>
        <p>
          Tenemos más de 9 años de experiencia, cuidando el patrimonio de nuestros clientes al
          contar con la supervisión requerida en cada una de nuestras áreas, lo que certifica la
          calidad de nuestros servicios.
        </p>
      </div>
    </div>
  </div>

  <!-- Servicios -->
  <div class="subtitle text-center py-4">
    <h2 class="fw-bold">
      CONOCE NUESTROS NUEVOS SERVICIOS
    </h2>
    <CarruselComponent></CarruselComponent>
  </div>

  <div class="py-4">
    <ServiciosReglaComponent></ServiciosReglaComponent>
  </div>

  <!-- Nuestros Clientes Carrusel -->
  <div class="subtitle text-center py-4">
    <h2 class="fw-bold">
      NUESTROS CLIENTES
    </h2>
    <CarruselCliente></CarruselCliente>
  </div>

</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue'
import CarruselComponent from '@/components/CarruselComponent.vue'
import CarruselCliente from '@/components/CarruselCliente.vue';
import BotonWsp from '@/components/BotonWsp.vue';
import ServiciosReglaComponent from '@/components/ServiciosReglaComponent.vue';

export default {
  components: {
    HeroComponent,
    CarruselComponent,
    CarruselCliente,
    BotonWsp,
    ServiciosReglaComponent
  }
}
</script>

<style scoped>
.texto-2 {
  font-size: 1rem;
  text-align: justify;
  color: #002169;
  padding: 1rem;
  max-width: 800px;
  margin: 0 auto;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
  .content-section {
    padding: 1rem 0.5rem;
    margin-top: 0;
  }

  .tablet-image{
    max-width: 250px;
    max-height: 250px;
  }

  .texto-2 {
    font-size: 0.875rem;
    padding: 0.5rem;
  }
}

/* Specific tablet styles */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .content-section {
    padding: 1rem;
    margin-top: 1rem;
  }

  .row {
    margin: 0;
  }

  .tablet-image {
    max-width: 90%;
    margin: 0 auto;
  }

  .texto-2 {
    font-size: 0.95rem;
    padding: 0.75rem;
  }
}

/* Medium tablet style */
@media screen and (min-width: 768px) and (max-width: 819px) {
  .content-section {
    margin-top: 0.5rem;
  }
}

/* Large tablet and small desktops */
@media screen and (min-width: 1100px) and (max-width: 1199px) {
  .content-section {
    margin-top: 1.5rem;
  }

  .texto-2 {
    font-size: 1rem;
  }
}
</style>
