<template>
    <a href="https://wa.me/4432725058" target="_blank" class="whatsapp-link">
      <button class="animate__animated animate__heartBeat whatsapp-button">
        <img src="../assets/iconos/whats flotante.svg" alt="WhatsApp">
      </button>
    </a>
  </template>
  
  <script>
  export default {
    name: 'BotonWsp'
  }
  </script>

  <style scoped>
  .whatsapp-link {
    position: fixed;
    bottom: 5%;
    right: 3%;
    z-index: 1000;
  }
  
  .whatsapp-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .whatsapp-button img {
    width: 50px;
    height: 50px;
    transition: width 0.3s ease, height 0.3s ease;
  }
  
  @media screen and (max-width: 768px) {
    .whatsapp-link {
      bottom: 3%;
      right: 2%;
    }
  
    .whatsapp-button img {
      width: 40px;
      height: 40px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .whatsapp-link {
      bottom: 2%;
      right: 1%;
    }
  
    .whatsapp-button img {
      width: 35px;
      height: 35px;
    }
  }
  
  /* Hover effect */
  .whatsapp-button:hover {
    transform: scale(1.1);
  }
  
  /* Accessibility and touch-friendly */
  @media (pointer: coarse) {
    .whatsapp-button {
      min-width: 50px;
      min-height: 50px;
    }
  }
  </style>