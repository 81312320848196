<template>
    <button v-if="pageName.toLowerCase() === 'guardias' || pageName.toLowerCase() === 'intendencia'"
    class="contact-button" @click="navigateToContact">Cotizar</button>
</template>

<script>
export default {
  name: 'ContactButtonComponent',
  props: {
    pageName: {
      type: String,
      required: true
    }
  },
  computed: {
    getImagePath() {
      return require(`@/assets/imagenes/${this.pageName.toLowerCase()}.svg`);
    }
  },
  methods: {
    navigateToContact() {
      this.$emit("scrollToContactRequest"); // Notifica al padre que se necesita el scroll
    }
  }
}
</script>

<style scoped>

.contact-button {
  text-align: center;
  background-color: #FFC844;
  color: #002169 !important;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  border: none;
}

.contact-button:hover {
  background-color: #002169; /*rgb(0, 47, 108);*/
  color: white !important;
}

/* Responsive adjustments */

/* Small devices (phones) */
@media (max-width: 575.98px) {
  .hero-container {
    max-height: 400px; /* Incrementamos el tamaño para móviles */
  }
  .hero-image {
    max-height: 400px;
  }
  .contact-button {
    font-size: 0.875rem;
    padding: 8px 16px;
    bottom: 12%; /* Ajustamos para que se vea mejor en móviles */
    left: 5%;
  }
}

/* Medium devices (tablets) */
@media (max-width: 991.98px) {
  .hero-container {
    max-height: 300px;
  }
  .hero-image {
    max-height: 300px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) {
  .hero-container {
    max-height: 600px;
  }
  .hero-image {
    max-height: 600px;
  }
}
</style>