<template>
    <BotonWsp></BotonWsp>
    
    <FormContactComponent></FormContactComponent>
    <br>
    <ServiciosReglaComponent></ServiciosReglaComponent>
    <!-- Servicios -->
    <div class="subtitle text-center py-4">
        <h2 class="fw-bold">
            CONOCE NUESTROS NUEVOS SERVICIOS
        </h2>
        <CarruselComponent></CarruselComponent>
    </div>
</template>

<script>
import CarruselComponent from '@/components/CarruselComponent.vue';
import ServiciosReglaComponent from '@/components/ServiciosReglaComponent.vue';
import BotonWsp from '@/components/BotonWsp.vue';
import FormContactComponent from '@/components/FormContactComponent.vue';





export default {
    components: {
        CarruselComponent,
        BotonWsp,
        ServiciosReglaComponent,
        FormContactComponent
    },
    data() {
        return {
            nombre: '',
            email: '',
            telefono: '',
            mensaje: '',
            servicioInteres: '',
            // Lista de servicios
            servicios: [
                { id: "", description: 'Seleccione su servicio de interés', selected: true, disable: true },
                { id: "Guardia", description: 'Guardias', selected: false, disable: false },
                { id: "Intendencia", description: 'Intendencia', selected: false, disable: false },
            ]
        }
    },
    methods: {
        async submitForm() {
            try {
                const response = await fetch('/backend/contacto.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    body: new URLSearchParams({
                        nombre: this.nombre,
                        email: this.email,
                        telefono: this.telefono,
                        mensaje: this.mensaje,
                        servicioInteres: this.servicioInteres
                    })
                });
                const result = await response.json();

                if (response.ok) {
                    alert(result.msg);
                    // Limpiar los campos del formulario
                    this.nombre = '';
                    this.email = '';
                    this.telefono = '';
                    this.mensaje = '';
                    this.servicioInteres = '';
                } else {
                    alert('Error al enviar el formulario: ' + result.msg);
                }
            } catch (error) {
                console.error('Error:', error);
                alert('Error al enviar el formulario');
            }
        }
    }

};
</script>

<style scoped>

</style>
