<template>
    <div class="container my-5">
        <h2>Nuestros Servicios</h2>
        <p>COTIZA.</p>
    </div>
</template>

<script>
export default {
    name: 'CotizaPage'
};
</script>
<style>
</style>