<template>
    <BotonWsp></BotonWsp>
    <section>
      <!-- Primera Sección: Imagen a la izquierda y texto a la derecha -->
      <div class="nosotros-container animate__animated animate__backInLeft">
        <div class="nosotros-content">
          <img src="../assets/iconos/somos.svg" alt="Imagen Nosotros">
          <p class="valores">Soluciones Integrales de Personal CMV S.C. de P. de R.L. de C.V., nace como una
            cooperativa
            basada en valores éticos sólidos, fundamentales para definir con claridad los principios de
            conducta de todos los que en ella colaboramos, apegados siempre a su misión, visión, valores y
            principios que se persiguen.</p>
        </div>
      </div>
  
      <!-- Sección de Visión -->
      <div class="nosotros-container animate__animated animate__backInRight">
        <div class="nosotros-content">
          <img src="../assets/iconos/vision.svg" alt="">
          <div>
            <div class="titulo-contenedor-V">
              <h2 class="animate__animated animate__backInLeft title-valores">VISIÓN</h2>
            </div>
            <p class="valores">"Ser reconocidos por nuestra responsabilidad, confianza, calidad y excelencia en la
              prestación
              de productos y servicios, contribuyendo al desarrollo socioeconómico de socios y
              colaboradores, basados en principios y valores".</p>
          </div>
        </div>
      </div>
  
      <!-- Sección de Misión -->
      <div class="nosotros-container animate__animated animate__backInLeft">
        <div class="nosotros-content">
          <img src="../assets/iconos/mision.svg" alt="">
          <div>
            <div class="titulo-contenedor-M">
              <h2 class="animate__animated animate__backInLeft title-valores">MISIÓN</h2>
            </div>
            <p class="valores">Somos una Cooperativa de producción responsable y confiable que contribuye al desarrollo
              económico de socios y colaboradores; enfocados a prestar servicios de vigilancia, intendencia
              y complementarios de personal, con calidad y sentido humano".</p>
          </div>
        </div>
      </div>
  
      <div class="valores-container ">
        <h1 class="text-center fw-bold animate__animated animate__backInUp" style="color: #002169;">
          Nuestros valores
        </h1>
        <!-- Contenedor de hexágonos -->
        <div class="hexagon-container">
          <div class="hexagon animate__animated animate__heartBeat">
            <img src="../assets/iconos/lealtad.svg" alt="Lealtad">
            <span class="fw-bold" style=" color: #8BC34A;">LEALTAD</span>
          </div>
          <div class="hexagon animate__animated animate__heartBeat">
            <img src="../assets/iconos/actitud de servicio.svg" alt="Actitud de Servicio">
            <span class="fw-bold" style="color: #FF5722;">ACTITUD DE SERVICIO</span>
          </div>
          <div class="hexagon animate__animated animate__heartBeat">
            <img src="../assets/iconos/responsabilidad.svg" alt="Responsabilidad">
            <span class="fw-bold" style="color: #ffc844;">RESPONSABILIDAD</span>
          </div>
          <div class="hexagon animate__animated animate__heartBeat">
            <img src="../assets/iconos/respeto.svg" alt="Respeto">
            <span class="fw-bold" style="color:#673AB7">RESPETO</span>
          </div>
          <div class="hexagon animate__animated animate__heartBeat">
            <img src="../assets/iconos/honestidad.svg" alt="Honestidad">
            <span class="text" style="color: #2CC2ED;">HONESTIDAD</span>
          </div>
        </div>
      </div>
    </section>

      <!-- Nuestros Clientes Carrusel -->
  <div class="subtitle text-center py-4">
    <h2 class="fw-bold ">
      NUESTROS CLIENTES
    </h2>
    <CarruselCliente></CarruselCliente>
  </div>
    
</template>
  

<script>
import BotonWsp from  '@/components/BotonWsp.vue';
import CarruselCliente from '@/components/CarruselCliente.vue';
export default {
    components: {
        BotonWsp,
        CarruselCliente
    }
}
</script>
  
<style scoped>
/* Estilos base */
.nosotros-container {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.nosotros-content {
  background-image: url('../assets/iconos/fondo nosotros.svg');
  background-size: cover;
  background-position: center;
  padding: 40px;
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  gap: 20px;
}

.nosotros-content img {
  width: 150px;
  height: auto;
  flex-shrink: 0;
}

/* Títulos de Misión y Visión */
.titulo-contenedor-V, .titulo-contenedor-M {
  border-radius: 12px;
  padding: 2px 15px;
  margin-bottom: 15px;
  display: inline-block;
}

.titulo-contenedor-V {
  background-color: #ffc844;
}

.titulo-contenedor-M {
  background-color: #2cc2ed;
}

.title-valores {
  color: #ffffff;
  font-size: 1.75rem;
  text-align: center;
  font-weight: bold;
  margin: 0;
}

.valores {
  text-align: justify;
  font-size: 1rem;
  color: #002169;
  margin: 0;
}

/* Sección de valores */
.valores-container {
  background-color: #EDEDED;
  padding: 40px 20px;
  text-align: center;
}

.hexagon-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5;
  max-width: 1200px;
  margin: 40px auto 0;
  padding: 0 20px;
}

.hexagon {
  position: relative;
  width: 100%;
  max-width: 190px;
  margin: 0 auto;
}

.hexagon:nth-child(odd) {
  margin-top: 50px;
}

.hexagon img {
  width: 100%;
  height: auto;
  display: block;
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .nosotros-content {
    padding: 30px;
  }

  .hexagon-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
  }

  .hexagon:nth-child(odd) {
    margin-top: 0;
  }

  .hexagon:nth-child(3n+2) {
    margin-top: 30px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .nosotros-container {
    padding: 15px;
  }

  .nosotros-content {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .nosotros-content img {
    width: 120px;
    margin-bottom: 20px;
  }

  .title-valores {
    font-size: 1.5rem;
  }

  .valores {
    font-size: 0.95rem;
  }

  .hexagon-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px;
  }

  .hexagon {
    max-width: 200px;
  }

  .hexagon:nth-child(3n+2) {
    margin-top: 0;
  }

  .hexagon:nth-child(even) {
    margin-top: 20px;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .nosotros-content {
    padding: 15px;
  }

  .hexagon-container {
    grid-template-columns: repeat(1, 1fr);
    gap: 35px;
  }

  .hexagon {
    max-width: 200px;
  }

  .title-valores {
    font-size: 1.25rem;
  }

  .valores {
    font-size: 0.9rem;
  }
}
</style>