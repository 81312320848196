import { createApp } from 'vue';
import App from './App.vue'; // Componente raíz
import router from './router'; // Importa el archivo de rutas

// Importar estilos globales (Bootstrap, íconos, etc.)
import 'bootstrap/dist/css/bootstrap.min.css'; // Para los estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Para el JavaScript de Bootstrap
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Crear y montar la aplicación de Vue
createApp(App)
  .use(router) // Usa el router configurado
  .mount('#app');
