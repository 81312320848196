<template>
  <div id="app">
    <!-- Header y Navbar estarán visibles en todas las páginas -->
    <HeaderComponent />
    <NavbarComponent />
    <br>

    <!-- Contenedor principal para el contenido dinámico -->
    <main class="main-content">
      <router-view :key="$route.fullPath"></router-view>
    </main>

    <!-- Footer visible en todas las páginas -->
    <FooterComponent />
  </div>
</template>

<script>
// Importa los componentes globales
import 'bootstrap/dist/css/bootstrap.min.css'; // Para los estilos de Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Para el JavaScript de Bootstrap
import HeaderComponent from './components/HeaderComponent.vue';
import NavbarComponent from './components/NavbarComponent.vue';
import FooterComponent from './components/FooterComponent.vue';
import 'animate.css';

export default {
  name: 'App',
  components: {
    HeaderComponent,
    NavbarComponent,
    FooterComponent,
  },
};
</script>

<style>
/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  font-family: 'MADE TOMMY';
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Contenedor principal */
.main-content {
  flex: 1;
  padding-top: 120px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .main-content {
    padding-top: 150px; /* Ajusta para móviles según necesites */
  }
}

@media (max-width: 480px) {
  .main-content {
    padding-top: 22.5%; /* Ajusta para dispositivos más pequeños */
  }
}
</style>