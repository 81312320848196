<template>
    <BotonWsp></BotonWsp>
    <HeroComponent pageName="guardias" @scrollToContactRequest="handleScrollToContact"></HeroComponent>

    <!-- Contenido de Guardias -->
    <div class="content-section">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-4 py-4">
                <img src="../assets/imagenes/guardias2.svg" alt="Descripción de la imagen"
                    class="img-fluid mx-auto d-block tablet-image">
            </div>
            <div class="col-12 col-lg-6 col-md-7">
                <div class="texto-2">
                    <h2 class="animate__animated  animate__zoomIn fw-bold">SEGURIDAD PRIVADA</h2>
                    <p>
                        Contamos con Guardias de Seguridad en modalidad Intramuros, quienes tienen como
                        objetivo principal vigilar, salvaguardar y proteger tu patrimonio, minimizando el riesgo de
                        asalto y contingencias.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjetas con texto e imagen -->
    <div class="container py-4 col-10">
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <!-- Tarjeta 1 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Que tu negocio <br> esté protegido siempre</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/seguridad 1.svg" alt="Icono negocio">
                            </div>
                        </div>
                        <p class="card-text">
                            Ofrecemos protección de 12 y 24 horas los días de la semana que lo requieras. Contamos con
                            roles de turno y sistema de suplencias, que permite tener siempre cubiertos los puestos.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 2 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Guardias Calificados</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/seguridad2.svg" alt="Icono guardias">
                            </div>
                        </div>
                        <p class="card-text">
                            Todos nuestros guardias de seguridad reciben capacitación constante en temas de seguridad y
                            servicio al cliente. Cada elemento pasa por un proceso detallado de reclutamiento y
                            selección.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 3 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Equipo de Seguridad</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/seguridad3.svg" alt="Icono seguridad">
                            </div>
                        </div>
                        <p class="card-text">
                            Nuestro personal cuenta con uniforme avalado para Seguridad Privada. Se les proporciona
                            equipo de seguridad básico y especial, según las necesidades que se tengan.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 4 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Supervisión constante</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/seguridad4.svg" alt="Icono supervisión">
                            </div>
                        </div>
                        <p class="card-text">
                            Contamos con supervisores que vigilan el trabajo de cada uno de nuestro personal asignado,
                            garantizando que se realicen correctamente las actividades y detectar necesidades
                            oportunamente.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- banner middle page -->
    <div class="text-center py-5" style="background-color: #002f6c; color: white;">
        <h3 class="text-warning fw-bold mb-0">Seguridad Privada</h3>
        <h1 class="fw-bold mt-1">Trabaja y Vive en un Entorno Seguro</h1>
        <ContactButtonComponent pageName="guardias" @scrollToContactRequest="handleScrollToContact">
        </ContactButtonComponent>
    </div>
    <br>
    <div class="py-4">
        <ServiciosReglaComponent></ServiciosReglaComponent>
    </div>
    <!-- Servicios -->
    <div class="subtitle text-center py-4">
        <h2 class="fw-bold">CONOCE NUESTROS NUEVOS SERVICIOS</h2>
        <CarruselComponent></CarruselComponent>
    </div>
    <hr>
    <FormContactComponent typeService="Guardia" ref="targeContact"></FormContactComponent>
</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue'
import BotonWsp from '@/components/BotonWsp.vue';
import ContactButtonComponent from '@/components/ContactButtonComponent.vue';
import ServiciosReglaComponent from '@/components/ServiciosReglaComponent.vue';
import CarruselComponent from '@/components/CarruselComponent.vue';
import FormContactComponent from '@/components/FormContactComponent.vue';

export default {
    components: {
        HeroComponent,
        BotonWsp,
        ContactButtonComponent,
        CarruselComponent,
        ServiciosReglaComponent,
        FormContactComponent
    },
    methods: {
        handleScrollToContact() {
            const targeContact = this.$refs.targeContact;
            if (targeContact) {
                targeContact.scrollToTargetForm(); // Llama al método del Hijo B
            }
        }
    }
}
</script>

<style scoped>
.card {
    border: none;
    background-color: #EDEDED;
    padding: 1.5rem;
}

.card-body {
    padding: 0;
}

.card-header-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
}

.card-title {
    color: #002169;
    font-size: 1.5rem;
    margin: 0;
    flex: 1;
    text-align: left
}

.card-icon {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: flex-start;
}

.card-icon img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.card-text {
    color: #002169;
    font-size: 1rem;
    text-align: justify;
    margin: 0;
}

.texto-2 {
    font-size: 1rem;
    text-align: justify;
    color: #002169;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
    .content-section {
        padding: 1rem 0.5rem;
        margin-top: 0;
    }

    .tablet-image {
        max-width: 250px;
        max-height: 250px;
    }

    .texto-2 {
        font-size: 0.875rem;
        padding: 0.5rem;
    }

    .card {
        padding: 1rem;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .card-icon {
        width: 40px;
        height: 40px;
    }
}

/* Tablet styles */
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .content-section {
        padding: 1rem;
        margin-top: 1rem;
    }

    .row {
        margin: 0;
    }

    .tablet-image {
        max-width: 90%;
        margin: 0 auto;
    }

    .texto-2 {
        font-size: 0.95rem;
        padding: 0.75rem;
    }

    .card-title {
        font-size: 1.35rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 819px) {
    .content-section {
        margin-top: 0.5rem;
    }

    .tablet-image {
        max-width: 250px;
        max-height: 250px;
    }

}

@media screen and (min-width: 1100px) and (max-width: 1199px) {
    .content-section {
        margin-top: 1.5rem;
    }

    .texto-2 {
        font-size: 1rem;
    }
}
</style>