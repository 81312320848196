<template>
  <div class="p-4">
    <div
      id="carouselClients"
      class="carousel slide"
      data-bs-ride="carousel"
      data-bs-interval="3000"
    >
      <!-- Indicadores de páginas en forma de puntos -->
      <div class="carousel-indicators">
        <button
          v-for="(group, groupIndex) in groupedClientes"
          :key="'indicator-' + groupIndex"
          type="button"
          data-bs-target="#carouselClients"
          :data-bs-slide-to="groupIndex"
          :class="{ active: groupIndex === 0 }"
          aria-current="groupIndex === 0 ? 'true' : undefined"
          :aria-label="'Slide ' + (groupIndex + 1)"
        ></button>
      </div>

      <!-- Contenido del carrusel -->
      <div class="carousel-inner">
        <div
          v-for="(group, groupIndex) in groupedClientes"
          :key="'carousel-' +groupIndex"
          class="carousel-item"
          :class="{ active: groupIndex === 0 }"
        >
          <div class="row justify-content-center align-items-center">
            <div
              v-for="(cliente, clienteIndex) in group"
              :key="'clients-' +clienteIndex"
              class="col-lg-2 col-md-3 col-sm-4 col-6 text-center logo-column"
            >
              <div class="logo-container">
                <img class="d-block mx-auto logo-img" :src="cliente.logo" :alt="cliente.nombre" />
              </div>
            </div>
          </div>
        </div>
      </div>
       <a
        class="carousel-control-prev custom-control"
        href="#carouselClients"
        role="button"
        data-bs-slide="prev"
      >
        <i class="bi bi-arrow-left"></i>
      </a>
      <a
        class="carousel-control-next custom-control"
        href="#carouselClients"
        role="button"
        data-bs-slide="next"
      >
        <i class="bi bi-arrow-right"></i>
      </a> 
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, onBeforeUnmount, computed } from 'vue';

// Lista de clientes
const clientes = [
  { nombre: "Cliente 1", logo: require("@/assets/CLIENTES/cliente1.svg") },
  { nombre: "Cliente 2", logo: require("@/assets/CLIENTES/cliente2.svg") },
  { nombre: "Cliente 3", logo: require("@/assets/CLIENTES/cliente3.svg") },
  { nombre: "Cliente 4", logo: require("@/assets/CLIENTES/cliente4.svg") },
  { nombre: "Cliente 5", logo: require("@/assets/CLIENTES/cliente5.svg") },
  { nombre: "Cliente 6", logo: require("@/assets/CLIENTES/cliente6.svg") },
  { nombre: "Cliente 7", logo: require("@/assets/CLIENTES/cliente7.svg") },
  { nombre: "Cliente 8", logo: require("@/assets/CLIENTES/cliente8.svg") },
  { nombre: "Cliente 9", logo: require("@/assets/CLIENTES/cliente9.svg") },
  { nombre: "Cliente 10", logo: require("@/assets/CLIENTES/cliente10.svg") },
  { nombre: "Cliente 11", logo: require("@/assets/CLIENTES/cliente11.svg") },
  { nombre: "Cliente 12", logo: require("@/assets/CLIENTES/cliente12.svg") },
  { nombre: "Cliente 13", logo: require("@/assets/CLIENTES/cliente13.svg") },
  { nombre: "Cliente 14", logo: require("@/assets/CLIENTES/cliente14.svg") },
  { nombre: "Cliente 15", logo: require("@/assets/CLIENTES/cliente15.svg") },
  { nombre: "Cliente 16", logo: require("@/assets/CLIENTES/cliente16.svg") },
  { nombre: "Cliente 17", logo: require("@/assets/CLIENTES/cliente17.svg") },
  { nombre: "Cliente 18", logo: require("@/assets/CLIENTES/cliente18.svg") },
  { nombre: "Cliente 19", logo: require("@/assets/CLIENTES/cliente19.svg") },
];

// Número de elementos visibles por fila
const visibleItems = ref(4);

// Agrupar clientes para el carrusel
const groupedClientes = computed(() => {
  const groups = [];
  for (let i = 0; i < clientes.length; i += visibleItems.value) {
    groups.push(clientes.slice(i, i + visibleItems.value));
  }
  return groups;
});

// Actualizar el número de elementos visibles según el tamaño de la ventana
const updateVisibleItems = () => {
  if (window.innerWidth >= 992) {
    visibleItems.value = 4;
  } else if (window.innerWidth >= 768) {
    visibleItems.value = 3;
  } else {
    visibleItems.value = 1;
  }
};

// Inicializar y limpiar el carrusel
onMounted(() => {
  updateVisibleItems();
  window.addEventListener("resize", updateVisibleItems);

  const carouselElement = document.querySelector("#carouselClients");
  if (carouselElement) {
    new bootstrap.Carousel(carouselElement, {
      interval: 3000,
      ride: "carousel",
    });
  }
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateVisibleItems);
});
</script>

<style scoped>
.logo-container {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
}

.logo-img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.carousel-indicators {
  bottom: -30px;
  margin-top: 20px;
}

.carousel-indicators button {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ccc;
  border: none;
  margin: 0 6px;
}

.carousel-indicators .active {
  background-color: #0044cc;
}

.custom-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #002169;
  font-size: 2rem;
  text-decoration: none;
}

.custom-control:hover {
  color: #0044cc;
}

.carousel-control-prev {
  left: -2rem;
}

.carousel-control-next {
  right: -2rem;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .logo-container {
    margin: 0 10px;
  }
}

@media (max-width: 992px) {
  .logo-container {
    margin: 0 8px;
  }
}

@media (max-width: 768px) {
  .logo-container {
    margin: 0 5px;
    height: 80px;
  }

  .carousel-indicators {
    bottom: -20px;
  }
}

@media (max-width: 576px) {
  .logo-container {
    margin: 0 3px;
    height: 60px;
  }

  .carousel-indicators {
    bottom: -15px;
  }
}
</style>
