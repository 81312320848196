<template>
    <BotonWsp></BotonWsp>
    <HeroComponent pageName="intendencia"  @scrollToContactRequest="handleScrollToContact"></HeroComponent>

    <!-- Contenido de Intendencia -->
    <div class="content-section">
        <div class="row align-items-center justify-content-center">
            <div class="col-12 col-lg-4 py-4">
                <img src="../assets/imagenes/intendencia2.svg" alt="Descripción de la imagen"
                    class="img-fluid mx-auto d-block tablet-image">
            </div>
            <div class="col-12 col-lg-6 col-md-7">
                <div class="texto-2">
                    <h2 class="animate__animated animate__zoomIn fw-bold">INTENDENCIA CORPORATIVA</h2>
                    <p>
                        Contamos con Personal Profesional para la limpieza diaria de tus instalaciones, de acuerdo 
al horario y necesidades que tengas. Cuidamos en todo momento la optimización del 
tiempo, los materiales y la atención de la limpieza a detalle.
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- Tarjetas con texto e imagen -->
    <div class="container py-4 col-10">
        <div class="row row-cols-1 row-cols-md-2 g-4">
            <!-- Tarjeta 1 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Limpieza Profesional para
                                cualquier área de trabajo</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/intendencia 1.svg" alt="Icono negocio">
                            </div>
                        </div>
                        <p class="card-text">
                            Ofrecemos Limpieza Profesional para instalaciones comerciales, 
corporativas e industriales. Damos orden y limpieza a tus áreas de 
trabajo y producción, oficinas y áreas de servicio al cliente.</p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 2 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">¡Olvídate del material de limpieza!</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/intendencia2.svg" alt="Icono guardias">
                            </div>
                        </div>
                        <p class="card-text">
                            Te ofrecemos suministro y dotación de material y equipo de limpieza 
en tu servicio, con la cantidad y frecuencia con la que lo requieras. 
Que el material de limpieza no sea una excusa, mantén siempre 
limpios tus espacios</p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 3 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Amamos los detalles</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/intendencia3.svg" alt="Icono seguridad">
                            </div>
                        </div>
                        <p class="card-text">
                            Cuidamos en todo momento la atención de la limpieza a detalle, 
sabemos la importancia de dar una buena imagen a tus clientes. Por 
ello, optimizamos el tiempo, los materiales y la dedicación en cada 
servicio, para ofrecerte siempre lo mejor. </p>
                    </div>
                </div>
            </div>

            <!-- Tarjeta 4 -->
            <div class="col">
                <div class="card h-100 shadow-sm">
                    <div class="card-body">
                        <div class="card-header-flex">
                            <h5 class="card-title fw-bold">Supervisión constante</h5>
                            <div class="card-icon">
                                <img src="../assets/iconos/intendencia4.svg" alt="Icono supervisión">
                            </div>
                        </div>
                        <p class="card-text">
                            Contamos con supervisores que vigilan el trabajo de cada uno de 
nuestro personal asignado, con ello garantizamos que se realicen
correctamente las actividades y detectar necesidades oportunamente.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- banner middle page -->
    <div class="text-center py-5" style="background-color: #002f6c; color: white;">
        <h3 class="text-warning fw-bold mb-0">Intendencia Corporativa e Industrial</h3>
        <h1 class="fw-bold mt-1">Oficinas y Espacios Impecables</h1>
        <ContactButtonComponent pageName="intendencia"  @scrollToContactRequest="handleScrollToContact"></ContactButtonComponent>
    </div>
    <div class="py-4">
    <ServiciosReglaComponent></ServiciosReglaComponent>
  </div>
    <!-- Servicios -->
    <div class="subtitle text-center py-4">
        <h2 class="fw-bold">CONOCE NUESTROS NUEVOS SERVICIOS</h2>
        <CarruselComponent></CarruselComponent>
    </div>
    <hr>
    <FormContactComponent typeService="Intendencia" ref="targeContact"></FormContactComponent>

</template>

<script>
import HeroComponent from '@/components/HeroComponent.vue'
import BotonWsp from '@/components/BotonWsp.vue';
import ContactButtonComponent from '@/components/ContactButtonComponent.vue';
import ServiciosReglaComponent from '@/components/ServiciosReglaComponent.vue';
import CarruselComponent from '@/components/CarruselComponent.vue';
import FormContactComponent from '@/components/FormContactComponent.vue';

export default {
    components: {
        HeroComponent,
        BotonWsp,
        ContactButtonComponent,
        CarruselComponent,
        ServiciosReglaComponent,
        FormContactComponent
    },
    methods: {
        handleScrollToContact() {
            const targeContact = this.$refs.targeContact;
            if (targeContact) {
                targeContact.scrollToTargetForm(); // Llama al método del Hijo B
            }
        }
    }
}
</script>

<style scoped>
.card {
    border: none;
    background-color: #EDEDED;
    padding: 1.5rem;
}

.card-body {
    padding: 0;
}

.card-header-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;
    gap: 1rem;
}

.card-title {
    color: #002169;
    font-size: 1.5rem;
    margin: 0;
    flex: 1;
    text-align: left
}

.card-icon {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: flex-start;
}

.card-icon img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.card-text {
    color: #002169;
    font-size: 1rem;
    text-align: justify;
    margin: 0;
}

.texto-2 {
    font-size: 1rem;
    text-align: justify;
    color: #002169;
    padding: 1rem;
    max-width: 800px;
    margin: 0 auto;
}

/* Mobile styles */
@media screen and (max-width: 767px) {
    .content-section {
        padding: 1rem 0.5rem;
        margin-top: 0;
    }
    .tablet-image{
    max-width: 250px;
    max-height: 250px;
  }


    .texto-2 {
        font-size: 0.875rem;
        padding: 0.5rem;
    }

    .card {
        padding: 1rem;
    }

    .card-title {
        font-size: 1.25rem;
    }

    .card-icon {
        width: 40px;
        height: 40px;
    }
}

/* Tablet styles */
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .content-section {
        padding: 1rem;
        margin-top: 1rem;
    }

    .row {
        margin: 0;
    }

    .tablet-image {
        max-width: 90%;
        margin: 0 auto;
    }

    .texto-2 {
        font-size: 0.95rem;
        padding: 0.75rem;
    }

    .card-title {
        font-size: 1.35rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 819px) {
    .content-section {
        margin-top: 0.5rem;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1199px) {
    .content-section {
        margin-top: 1.5rem;
    }
    .texto-2 {
        font-size: 1rem;
    }
}
</style>