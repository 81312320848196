<template>
  <nav class="navbar navbar-expand-md navbar-light bg-white fixed-top shadow-sm">
    <div class="container">
      <!-- Logo -->
      <router-link class="navbar-brand d-flex align-items-center" to="/">
        <img src="../assets/logo sip.svg" alt="SIP Logo" class="logo" />
      </router-link>

      <!-- Hamburger Menu Button -->
      <button class="navbar-toggler border-0" type="button" @click="toggleMenu" :aria-expanded="isMenuOpen"
        aria-controls="navbarNav" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Navigation Links Container -->
      <div class="collapse navbar-collapse justify-content-between" :class="{ 'show': isMenuOpen }" id="navbarNav">
        <!-- Centered Navigation Links -->
        <div class="mx-auto">
          <ul class="navbar-nav align-items-center gap-3">
            <li class="nav-item">
              <router-link to="/" class="nav-link" :class="{ 'active-link': $route.path === '/' }" exact
                @click="closeMenu">
                Inicio
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/guardias" class="nav-link" :class="{ 'active-link': $route.path === '/guardias' }"
                @click="closeMenu">
                Guardias
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/intendencia" class="nav-link" :class="{ 'active-link': $route.path === '/intendencia' }"
                @click="closeMenu">
                Intendencia
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/nosotros" class="nav-link" :class="{ 'active-link': $route.path === '/nosotros' }"
                @click="closeMenu">
                Nosotros
              </router-link>
            </li>
            <li class="nav-item">
              <a href="http://191.101.165.110/ERP/Sip/" class="nav-link">Sistema de Atención</a>
            </li>
            <li class="nav-item">
              <router-link to="/contacto" class="quote-button nav-link fw-bold" :class="{ 'active-link': $route.path === '/contacto' }"
                @click="closeMenu">
                Cotiza Ahora
              </router-link>
            </li>

          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavbarComponent',
  data() {
    return {
      isMenuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    closeMenu() {
      this.isMenuOpen = false;
    }
  }
};
</script>

<style scoped>

.navbar {
  top: 40px;
}

.logo {
  height: 70px;
  width: auto;
}

.nav-link {
  color: #002169 !important;
  font-weight: 500;
  padding: 0.3rem !important;
  transition: all 0.3s ease;
  border-radius: 4px;
  text-align: center;
}

.active-link {
  background-color: #2CC2ED !important;
  color: white !important;
}

.nav-link:hover:not(.active-link):not(.quote-button) {
  color: #002169 !important;
}

.quote-button {
  text-align: center;
  background-color: #FFC844;
  color: #002169 !important;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.active-quote {
  background-color: #2CC2ED;
}

.quote-button:hover:not(.active-quote) {
  background-color: #001850;
  color: white !important;
  text-decoration: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0.5rem 1rem;
  }

  .navbar-collapse {
    background-color: white;
    padding: 1rem;
    margin-top: 0.5rem;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .nav-item {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #eee;
    padding: 0.5rem 0;
  }

  .nav-item:last-child {
    border-bottom: none;
  }

  .quote-button {
    display: block;
    margin: 0.5rem 0;
    text-align: center;
  }

  /* Center alignment for mobile */
  .navbar-nav {
    margin: 0 auto;
  }
}

@media (max-width: 480px) {
  .navbar {
    top: 30px;
    padding: 0.5rem;
  }

  .logo {
    height: 30px;
  }

  .nav-link {
    font-size: 0.9rem;
    padding: 0.7rem !important;
  }
}
</style>
